body {
  height: 100vh;
  position: relative;
}

#root {
  height: 100vh;
  position: relative;
}

.accordion__item + .accordion__item {
  border: none !important;
}

.accordion__button:before {
  width: 5px !important;
  height: 5px !important;
  border-bottom: 1px solid currentColor;
  border-right: 1px solid currentColor;
}

.badge-pill {
  font-weight: 700;
  background: #8061da;
  color: white;
  border-radius: 4px;
}

.note-group-pill {
  background: #8061da;
  color: white;
  font-size: 0.7rem;
}

.note-list-pill {
  background: #03aeef;
  color: white;
  font-size: 0.7rem;
}

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.fade-in.one {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.fade-in.two {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.fade-in.three {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.fade-in.four {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.content-block {
  padding-top: 6rem;
  .content-block-header {
    text-align: left;
    padding-left: 1rem;
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 767px) {
  .content-block {
    .content-block-header {
      text-align: center;
      font-size: 4rem;
      line-height: 4.5rem;
    }
  }
}

blockquote {
  position: relative;
  padding-left: 3rem;
  line-height: 1.1rem;

  &::after {
    position: absolute;
    top: 1rem;
    left: 1rem;
    content: '"';
    font-size: 3rem;
  }
}
