#auth-nav {
  .non-auth {
    display: block;
  }
  .auth {
    display: none;
  }

  &.is-auth {
    .non-auth {
      display: none;
    }
    .auth {
      display: block;
    }
  }
}

.evolve-navbar {
  background: white;
  border-bottom: 1px solid $light;
  .navbar-collapse {
    padding: 0 1rem 2rem 1rem;
    text-align: center;
    margin-top: 1rem;
    .nav-link {
      color: $dark;
      font-weight: 700;
      font-size: 1rem;
      border-bottom: 1px solid $light;
      padding: 1rem 1rem;
    }
    .btn {
      font-size: 1rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
    }
  }
  .navbar-toggler {
    border: none;
    background: $light;
  }
}
@media (min-width: 767px) {
  .evolve-navbar {
    .navbar-collapse { 
      padding: 0;
      margin-top: 0;
      .nav-link {
        border-bottom: none;
        padding: 0.5rem 1rem;
      }
      .btn {
        padding: 0.375rem 1rem;
      }
    }
  }
}