.pricing-page-container {
  padding: 10rem 2rem 0rem 2rem;
}
.pricing-header-container {
  text-align: left;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
}
.pricing-card-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 1rem;
  margin-top: 4rem;
  .pricing-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 450px;
    border-radius: 8px;
    padding: 1.25rem;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    color: $dark;
    .price-card-head {
      .price-text {
        font-weight: 400;
        font-size: 1.25rem;
        margin-bottom: 0.25rem;
      }
      .price-numeric {
        font-weight: 700;
        font-size: 3rem;
        margin-bottom: 0.25rem;
      }
      .price-unit {
        font-size: 1rem;
        margin-bottom: 1.75rem;
      }
    }
    .attribute-list {
      font-size: 1rem;
    }
    .btn-pricing {
      margin-top: auto; 
      font-size: 1rem;
    }
  }
  .white-card {
    background: white;
  }
  .gradient-card {
    background: linear-gradient(145deg, rgb(48, 55, 113) 15%, rgb(3, 174, 239) 100%);
    color: white;
  }
  .navy-card {
    background: $dark;
    color: white;
  }
}
.faq-wrapper {
  margin-top: 4rem;
  h1 {
    text-align: center;
    font-weight: 700;
    line-height: 3rem;
    font-size: 2.25rem;
    margin: 0px auto 2rem;
  }
  .accordion {
    border: none;
    margin-bottom: 6rem;
    .card {
      .card-header {
        padding: 0;
        .accordion-btn {
          padding: 1rem;
          font-size: 1rem;
          text-align: left;
          width: 100%;
        }
      }
      .card-body {
        font-size: 1rem;
      }
    }
  }
}
@media (min-width: 767px) {
  .pricing-page-container {
    padding: 12rem 2rem 0rem 2rem;
  }
  .pricing-header-container {
    text-align: center;
    h1 {
      font-size: 4rem;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 2rem;
      font-weight: 300;
      margin-bottom: 2.5rem;
    }
  }
  .pricing-card-container {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 6rem;
  }
  .faq-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
}
