.feature-row {
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 6rem;
  .feature-content {
    margin-bottom: 4rem;
    padding: 0 1rem;
    .feature-tag {
      font-weight: 700;
      font-size: 1.25rem;
      color: rgb(187, 187, 187);
      margin-bottom: 0;
    }
    .feature-heading {
      font-size: 2rem;
      margin-bottom: 1.25rem;
    }
    .feature-text {
      font-size: 1.25rem;
      font-weight: 300;
      margin-bottom: 1.25rem;
    }
  }
  .feature-text-left, 
  .feature-text-right {
    order: 1;
  }
  .feature-media {
    order: 2;
    position: relative;
    width: 100%;
    .bg-img-top-left {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -10;
      width: 30%;
    }
    .bg-img-top-right {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -10;
      width: 30%;
    }
    .bg-img-bottom-left {
      position: absolute;
      bottom: 10%;
      left: 0;
      z-index: -10;
      width: 30%;
    }
    .bg-img-bottom-right {
      position: absolute;
      bottom: 10%;
      right: 0;
      z-index: -10;
      width: 30%;
    }
    video {
      position: relative;
      width: 91%;
      margin: 1rem;
      z-index: 900;
      border-radius: 4px;
      box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
    }
  }
}
@media (min-width: 767px) {
  .feature-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12rem;
    .feature-content {
      flex: 1 1 auto;
      width: 80%;
      padding: 0;
      margin-bottom: 0;
      .feature-heading {
        font-size: 2.8rem;
      }
      .feature-text {
        font-size: 1.5rem;
      }
    }
    .feature-text-left {
      order: 1;
      margin-right: 6rem;
    }
    .feature-text-right {
      order: 3;
      margin-left: 6rem;
    }
    .feature-media {
      order: 2;
      flex: 1 1 auto;
      .bg-img-bottom-left {
        bottom: 2%;
      }
      .bg-img-bottom-right {
        bottom: 2%;
      }
    }
    .media-left {
      order: 1;
    }
    .media-right {
      order: 2;
    }
  }
}