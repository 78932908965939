.help-center-header {
  background: linear-gradient(145deg, rgb(48, 55, 113) 15%, rgb(3, 174, 239) 100%);
  text-align: center;
  color: white;
  margin-bottom: 3rem;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 4rem;
  }
}
.resources-section {
  border-top: 1px solid $gray-300;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 8rem;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  .resources-posts-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 1rem;
    .resource-post {
      border: 1px solid $gray-200;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5rem;
      transition: 0.3s ease;
      &:hover {
        text-decoration: none;
        background: rgba(48,55,113,0.15);
      }
      img {
        width: 30%;
        margin-right: 1rem;
        
      }
      p {
        margin-bottom: 0rem;
        color: $gray-900;
      }
    }
  }
}