.fancy-scrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0);
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: solid 1px transparent;
    box-shadow: inset 0 0 10px 10px lightgrey;
  }
}
