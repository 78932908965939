.article-container {
  header {
    margin-bottom: 1.5rem;
    .article-heading {
      font-size: 2.5rem;
      margin: 1.25rem 0;
    }
    .article-lead {
      color: $gray-600;
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 1.25rem;
    }
    .article-hero-img {
      width: 100%;
    }
  }
  .btn-breadcrumb {
    color: $gray-600;
    text-decoration: underline;
    font-size: 1rem;
  }
  .article-meta-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .article-meta-item {
      font-size: 0.6rem;
      margin-right: 1rem;
      font-weight: 700;
      color: $gray-600;
      line-height: 1.5rem;
    }
  }
  p,
  ol,
  ul,
  li,
  a {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li {
    color: $gray-900;
  }
  p,
  li {
    line-height: 1.85rem;
  }
  ol,
  ul,
  p,
  h2,
  h3 {
    margin-bottom: 1.75rem;
  }
  li {
    margin-bottom: 1rem;
  }
  img {
    max-width: 100%;
    border: 1px solid $gray-200;
    margin-bottom: 1.75rem;
  }
  h2,
  hr {
    margin-top: 3rem;
  }
  .half-width-img {
    width: 50%;
  }
}
.columns-wrapper {
  .resources-menu {
    display: none;
  }
}
@media (min-width: 767px) {
  .columns-wrapper {
    display: flex;
    position: relative;
    .resources-menu {
      display: flex;
      flex-direction: column;
      padding: 0 4rem;
      max-width: 350px;
      border-right: 1px solid $gray-300;
      height: 100vh;
      .sticky-side-nav {
        overflow-y: auto;
        overflow-x: hidden;
        h2 {
          font-size: 1.625rem;
          margin-bottom: 1.25rem;
          padding-top: 1.5rem;
        }
        .side-menu-resource-section {
          margin-bottom: 1.75rem;
          .resource-section-name {
            font-size: 1.125rem;
            margin-bottom: 0.75rem;
          }
          .resource-article {
            font-size: 0.8rem;
            color: $gray-700;
            transition: 0.3s ease;
            font-weight: 700;
            &:hover {
              color: $gray-600;
              text-decoration: none;
            }
            p {
              padding-left: 0.375rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
  .article-container {
    flex: 1 1 auto;
    max-width: 680px;
    margin: 0 auto;
    .article-meta-data {
      .article-meta-item {
        font-size: 0.8rem;
      }
    }
    p,
    ol,
    ul,
    li,
    a {
      font-size: 1.12rem;
    }
    p,
    li {
      line-height: 2.25rem;
    }
  }
}
