// Fonts

// Brand Colors ==========================
$purple: #8061DA;
$pink: #EC028C;
$blue: #03AEEF;

$primary: #03AEEF; 
$secondary: #DEE2E6;
$dark: #303771;
$light: #f4f5f7;

$gray-900: #303030;


// Brand fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|Ubuntu:700&display=swap');

$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Ubuntu', sans-serif;

$font-size-base: 1.125rem;

$body-color: #000000;