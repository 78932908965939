.footer-wrapper {
  background: $light;
  padding: 6rem 1rem 2rem 1rem;
  .container {
    padding: 0 2rem;
    .footer-menu {
      display: flex;
      flex-direction: column;
      .footer-menu-column {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 2rem;
        h5 {
          font-size: 1rem;
          color: $dark;
        }
        .footer-link {
          color: $dark;
          font-size: 0.75rem;
          margin-bottom: 0.35rem;
        }
      }
      .brand-column {
        text-align: left;
        align-items: flex-start;
      }
    }
  }
}
.footer-copy {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: $gray-600;
}
.sign-up-box {
  background: linear-gradient(145deg, rgb(48, 55, 113) 15%, rgb(3, 174, 239) 100%);
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
  color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  margin-bottom: 6rem;
  .content {
    flex: 1 1 auto;
    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    .lead {
      color: white;
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
    .sign-up-box-button {
      font-size: 1rem;
    }
  }
  .media {
    flex: 1 1 auto;
    display: none;
  }
}
@media (min-width: 767px) {
  .sign-up-box {
    padding: 4rem;
    flex-direction: row;
    position: relative;
    text-align: left;
    .content {
      h2 {
        font-size: 3rem;
      }
    }
    .media {
      display: inline;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .footer-wrapper {
    padding: 6rem 0rem 2rem 0rem;
    .container {
      .footer-menu {
        display: flex;
        flex-direction: row;
        padding-bottom: 3rem;
        .footer-menu-column {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 0;
        }
      }
    }
  }
}