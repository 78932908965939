.evolve-jumbotron {
  padding: 0 1rem;
  .jumbotron-content {
    text-align: center;
    padding: 10rem 1rem 4rem 1rem;
    h1 {
      color: $gray-900;
      font-size: 2.25rem;
      margin-bottom: 2rem;
      line-height: 3rem;
    }
    .lead {
      font-weight: 300;
      font-size: 1.12rem;
      margin-bottom: 1.5rem;
      line-height: 2rem;
    }
    a {
      font-weight: 700;
      padding: 0.75rem 2rem;
      font-size: 1rem;
    }
  }
  .media {
    padding: 0 1rem;
    video {
      width: 100%;
      border-radius: 4px;
      box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
      margin: 0 auto;
    } 
  }
}
.features-jumbotron {
  background: linear-gradient(145deg, rgb(48, 55, 113) 15%, rgb(3, 174, 239) 100%);
  color: white;
  padding: 12rem 1rem 3rem 1rem;
  border-radius: 0px;
  margin-bottom: 0rem;
  .container {
    display: flex;
    flex-direction: column;
    .left-items {
      h1 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
      }
      p {
        font-size: 1.25rem;
        margin-bottom: 2rem;
      }
    }
    .right-items {
      img {
        max-width: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
      }
    }
  }
}
@media (min-width: 767px) {
  .evolve-jumbotron {
    padding-top: 4rem;
    .jumbotron-content {
      width: 770px;
      margin: 0 auto 1rem auto;
      h1 {
        font-size: 5rem;
        max-width: 770px;
        margin: 0 auto 2rem auto;
        line-height: 5.5rem;
      }
      .lead {
        max-width: 650px;
        margin: 0 auto 3rem auto;
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
    .media {
      padding: 0;
      video {
        width: 767px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
      }
    }
  }
}
.features-jumbotron {
  overflow-x: hidden;
  .container {
    flex-direction: row;
    .left-items {
      width: 50%;
      padding-right: 3rem;
      h1 {
        font-size: 4rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
    .right-items { 
      width: 50%;
      img {
        max-width: 900px;
      }
    }
  }
}
@media (min-width: 1110px) {
  .evolve-jumbotron {
    .media {
      video {
        width: 1110px;
      }
    }
  }
}