@import "variables";
@import "bootstrap/bootstrap";
@import "page";
@import "scrollbars";
@import "accordion";
@import "evolve-jumbotron";
@import "feature-rows";
@import "navbar";
@import "footer";
@import "pricing";
@import "article";
@import "blog-index";
@import "resources-index";

body {
  // background-color: pink !important;
}
