.posts-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem 1rem;
  .post-card {
    border-radius: 4px;
    padding: 1rem;
    position: relative;
    color: $gray-900;
    transition: 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      text-decoration: none;
      background: rgba(48,55,113,0.15);
    }
    .post-teaser {
      .post-thumbnail {
        width: 100%;
        border: 1px solid $gray-300;
        border-radius: 4px; 
        margin-bottom: 1.25rem;
      }
      .post-title {
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: $gray-900;
      }
      .post-description {
        font-size: 1rem;
        color: $gray-600;
        line-height: 1.75rem;
      }
    }
    .post-meta-data {
      display: flex;
      flex-direction: row;
      margin-top: auto;
      .post-meta-item {
        font-size: 0.75rem;
        font-weight: 700;
        margin-right: 1rem;
        color: $gray-600;

      }
    }
  }
}
@media (min-width: 767px) {
  .blog-page-heading {
    font-size: 4rem;
    margin-bottom: 3rem;
    margin-top: 4rem;
  }
  .posts-grid {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 7rem;
  }
}